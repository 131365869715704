.lmj-banner {
	color: black;
	text-align: right;
	padding: 32px;
	border-bottom: solid 3px black;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	align-items: end;
}

.lmj-main-content{
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
	align-items: center;
}

.lmj-title {
	padding-left: 32px;
}

.lmj-logo {
	height: 45px;
	width: 45px;
}