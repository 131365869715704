.lmj-plant-item, .lmj-carescale {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
}

.lmj-plant-item {
	margin: 20px;
	text-transform: capitalize;
	position: relative;
	gap: 10px;
}

.lmj-plant-item-price {
	position: absolute;
	border-radius: 20px 0px 20px 0;
	background-color: #31b472;
	color: white;
	padding: 15px;
	right: 0;
	bottom: 33.9%;
	font-weight: 500;
}

.lmj-sales {
	position: absolute;
	left: 0px;
	top: 0px;
	font-weight: 500;
	color: white;
	padding: 5px 10px;
	background-color: #31b572;
	border-top-left-radius: 15px;
	border-bottom-right-radius: 15px;
}

.lmj-carescale {
	gap: 6px;
	cursor: pointer;
}

.lmj-modal-content {
	text-align: start;
}

.lmj-modal-content > p{
	margin-top: 0;
}

.lmj-modal-content > h4{
	margin-bottom: 7px;;
}